<template>
  <van-list
    v-model="loading"
    :finished="finished"
    finished-text="暂无更多"
    @load="onLoad"
  >
    <div
      class="dynamic"
      :class="{
        essence: item.essenceStatus == 0
      }"
      v-for="item in list"
      :key="item.id"
    >
      <van-cell
        @click="Goto(item.id)"
        class="user-info"
        center
        :border="false"
        style="background-color: transparent"
      >
        <div class="profile" slot="icon">
          <img :src="item.avatar" alt="" />
        </div>

        <div slot="title" class="user-name">{{ item.realname }}</div>
        <div slot="label" class="publish-date">{{ item.createTime }}</div>
        <!-- <template #right-icon>
          <van-button
            style="background-color: transparent"
            class="btn-item"
            @click="Goto(item.id)"
          >
            <template #icon>
              <img src="../../assets/img/jun-chuang/more.png" alt="" />
            </template>
          </van-button>
        </template> -->
        <van-tag plain class="success" v-if="item.toTop == 0" type="success"
          >置顶</van-tag
        >
      </van-cell>

      <van-tag class="success" v-if="item.essenceStatus == 0" type="success"
        >精华动态</van-tag
      >
      <div class="dynamic-content" @click="Goto(item.id)">
        <p>
          {{ item.textData }}
        </p>

        <div class="dynamic-img">
          <div
            class="img"
            v-for="(src, index) in item.pictureData"
            :key="index"
          >
            <img :src="src" alt="" />
          </div>
        </div>
        <!-- <div class="category">
          <p v-for="(related, index) in item.relatedTopicList" :key="index">
            {{ related }}
          </p>
        </div> -->
      </div>
       <div class="category">
          <p v-for="(related, index) in item.relatedTopicList" :key="index"  @click="GotoHot(related.id, related.name)">
            {{ related.name }}
          </p>
        </div>
      <div class="dynamic-bottom">
        <van-button class="btn-item" @click="addDiscuss(item)">
          <template #icon>
            <img src="../../assets/img/jun-chuang/info.png" alt="" />
            {{
              parseInt(item.comments) > 10000
                ? (item.comments / 10000).toFixed(1) + 'w'
                : item.comments
            }}
          </template>
        </van-button>
        <van-button class="btn-item" @click="onCollect(item)">
          <template #icon class="icon">
            <img
              v-if="item.sr == 0"
              src="../../assets/img/jun-chuang/praise.png"
              alt=""
            />
            <img v-else src="../../assets/img/jun-chuang/praise1.png" alt="" />
            {{
              parseInt(item.likes) > 10000
                ? (item.likes / 10000).toFixed(1) + 'w'
                : item.likes
            }}
          </template>
        </van-button>
        <van-button class="btn-item" @click="share(item)">
          <template #icon>
            <img src="../../assets/img/jun-chuang/share.png" alt="" />
            分享
          </template>
        </van-button>
      </div>
    </div>
    <!-- 发布评论 -->
    <van-popup
      style="max-width: 100%"
      @close="message = ''"
      v-model="isPostShow"
      position="bottom"
       id="inputID"
    >
      <div class="comment-post">
        <van-field
          class="post-field"
          v-model="message"
          rows="2"
          autosize
          type="textarea"
          maxlength="100"
          placeholder="请输入留言"
          show-word-limit
        />
        <van-button class="post-btn" @click="onPost" :disabled="!message.length"
          >发布</van-button
        >
      </div>
    </van-popup>
    <!-- 发布评论 -->
    <!-- 分享朋友圈 -->
    <!-- <van-popup v-model="isShare" position="bottom">
      <div>朋友圈</div>
    </van-popup> -->
    <!-- 分享朋友圈 -->
  </van-list>
</template>

<script>
import { debounce } from '@/utils/utils'
import {
  getdynamicList,
  addLike,
  addComment,
  addLikeDynamicComment,
  sendShare
} from '@/api/jun-chuang'
export default {
  name: '',
  components: {},
  props: {
    islike: false
  },
  data() {
    return {
      loading: false,
      finished: false,
      pageNo: 1,
      pageSize: 10,
      list: [],
      isPostShow: false,
      message: '',
      dynamicItem: null,
      id: null
      // isShare: false
    }
  },
  computed: {},
  created() {
    ZWJSBridge.onReady(() => {
      console.log('初始化完成后，执行bridge方法')
    })
  },
  mounted() {},
  methods: {
      inputClick(){
   document.querySelector('#inputID').scrollIntoView()
    },
    share(item) {
      ZWJSBridge.directShare({
        channel: 'wechat_moments',
        title: '军创圈动态分享',
        content: item.textData,
        image: item.pictureData[0],
        url: `${window.location.host}/#/dynamicDetails/${item.id}`
      })
        .then((result) => {
          sendShare().then((res) => {
            console.log(res)
          })
        })
        .catch((error) => {
          console.log(error)
        })
      // this.isShare = true
    },
    async onLoad() {
      if (!this.islike) {
        try {
          const result = await getdynamicList({
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            topicsId: this.$route.params.id
          })
          const list = result.records
          list.forEach((item) => {
            item.pictureData = item.pictureData.split(',')
          })
          for (var i = 0; i < list.length; i++) {
            if (list[i].toTop == 0) {
              list.splice(0, 0, list[i]) //index:元素需要放置的位置索引，从0开始
              list.splice(i + 1, 1) //移除原来位置上的该元素
            }
          }

          this.list.push(...list)
          this.loading = false
          if (result.records.length) {
            this.pageNo++
          } else {
            this.finished = true
          }
        } catch (err) {
          this.loading = false
        }
      } else {
        try {
          const result = await addLikeDynamicComment({
            pageNo: this.pageNo,
            pageSize: this.pageSize
          })
          const list = result.result.records
          list.forEach((item) => {
            item.pictureData = item.pictureData.split(',')
          })
          this.list.push(...list)
          this.loading = false
          if (result.result.records.length) {
            this.pageNo++
          } else {
            this.finished = true
          }
        } catch (err) {
          this.loading = false
        }
      }
    },
    onCollect: debounce(function (item) {
      addLike(item.id).then((res) => {
        if (res.result == 1) {
          item.sr = 0
          item.likes--
        } else {
          item.sr = 1
          item.likes++
        }
      })
    }, 500),
    // onCollect(item) {
    //   addLike(item.id).then((res) => {
    //     if (res.result == 1) {
    //       item.sr = 0
    //       item.likes--
    //     } else {
    //       item.sr = 1
    //       item.likes++
    //     }
    //   })
    // },
    addDiscuss(item) {
      this.dynamicItem = item
      this.isPostShow = true
    },
    async onPost() {
      this.$toast.loading({
        message: '评论中...',
        forbidClick: true, // 禁用背景点击
        duration: 0 // 持续时间，默认 2000，0 表示持续展示不关闭
      })

      try {
        const result = await addComment({
          discussData: this.message,
          dynamicId: this.dynamicItem.id,
          level: 1
        })

        this.message = ''
        // this.dynamicItem.comments++
        this.$toast.success('评论成功')
        this.isPostShow = false
      } catch (err) {
        this.$toast.fail('评论失败')
        this.isPostShow = false
      }
    },
    Goto(id) {
      this.$router.push({ name: 'dynamicDetails', params: { id: id } })
    },
     GotoHot(id, name) {
      this.$router.push({ name: 'hotDynamic', params: { id: id, name: name } })
    }
  }
}
</script>

<style lang="less" scoped>
.essence {
  background: #ffffff;
  background: linear-gradient(
      180deg,
      rgba(32, 206, 130, 0.1) 0%,
      rgba(16, 149, 91, 0) 100%
    ),
    #ffffff !important;
  box-shadow: 0px 4px 10px rgba(57, 84, 65, 0.06);
  border-radius: 14.5px;
}
.essenceTypeface {
  color: #395441;
}
.dynamic {
  padding-top: 14px;
  padding-bottom: 16px;
  margin-top: 27px;
  background: #ffffff;
  border-radius: 15px;
  .success {
    height: 30px;
    font-size: 18px;
    margin-top: 10px;
    margin-left: 20px;
  }

  .user-info {
    width: 295px;

    padding: 0;
    margin-left: 19px;
    .btn-item {
      border: none;
      padding: 0;
      height: 30px;
      font-size: 13px;
      color: #868a93;
    }
  }
  .profile {
    width: 50px;
    height: 50px;
    margin-right: 13px;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .user-name {
    font-size: 20px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #12151b;
    letter-spacing: 0px;
  }
  .publish-date {
   width: 165px;
    font-size: 15px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #868a93;
  }
  .dynamic-content {
    margin: 12px 25px 0 25px;
    overflow: hidden;

    p {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #12151b;
      line-height: 26px;
      letter-spacing: 0px;
    }
  }
  .dynamic-img {
    margin-top: 29px;
    display: flex;
    flex-flow: wrap;
    .img {
      background-color: rgb(255, 255, 255);
      margin-right: 4px;
      margin-bottom: 4px;
      width: 95px;
      height: 95px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .category {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0 6px 0px;
    align-content: space-between;
    p {
      margin-bottom: 10px;
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
      flex: 0 0 31%;
      margin-right: 10px;
      box-sizing: border-box;
      border-radius: 12px;
      width: 120px;

      line-height: 24px;
      background: #f7f7f7;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: center;
      color: #464a55;
      letter-spacing: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.dynamic-bottom {
  margin-left: 31px;
  margin-right: 26px;
  display: flex;
  justify-content: space-between;
  .btn-item {
    border: none;
    padding: 0;
    height: 30px;
    font-size: 18px;
    color: #868a93;
  }
  img {
    width: 24px;
    height: 24px;
  }
}
.comment-post {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 32px 0 32px 32px;
  .post-field {
    background-color: #f5f7f9;
  }
  .post-btn {
    width: 150px;
    border: none;
    padding: 0;
    color: #10955b;
    &::before {
      display: none;
    }
  }
}
</style>