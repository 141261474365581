<template>
  <div class="outermost">
    <layout class="layout" :hd="true" :ft="false">
      <!-- 右边按键 -->
      <div slot="rt"></div>
      <!-- 左边按键 -->
      <div slot="le" class="hd-left" @click="returnHome">
        <div></div>
        <span>我的点赞</span>
      </div>
    <DynamicList v-if="!$store.state.user.elderModel" :islike="true"/>
    <DynamicListElder v-else/>
    </layout>
  </div>
</template>

<script>
import layout from '@/components/layout.vue'
import DynamicList from '@/views/jun-chuang/dynamic-list.vue';
import DynamicListElder from '@/views/jun-chuang/dynamic-list-elder.vue';
export default {
  components: {
    layout,
    DynamicList,
    DynamicListElder
  },
  data() {
    return {
  
    }
  },
  created() {},
  methods: {
 
    // 返回上一页
    returnHome() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.outermost {
  background-color: #f2f3f5;
  .layout /deep/ .main {
    padding-bottom: 0;
  }
  .hd-left {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
      transform: rotate(225deg);
    }
    span {
      font-size: 18px;
      margin-left: 4px;
    }
  }
}

</style>


